<template>
  <div class="error404">
    <img src="../assets/img/iconjama.png" alt="">
    <h1>Error 404</h1>
    <p>Opps, página no encontrada.</p>
    <p>Lo sentimos, la página que busca no existe o no se puede encontrar</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.error404{
  height: 100vh;
  background-color: #222AC3;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img{
    width: 140px;
  }
}
</style>